@import '../../variables';
@import '../../mixins';

.List {
  grid-column: 1 / 5;

  .ListItem {

    @include transitionState($willEnter, $exiting) {
      opacity: 0;
    }

    @include transitionState($exiting) {
      transition: opacity 200ms ease;
    }

    @include transitionState($entered) {
      transition: opacity 200ms ease;
      opacity: 1;
    }
  }
}
.DeleteButton {
  margin: 4px 12px;
  display: flex;
  align-content: center;
  justify-content: center;

  .DeleteButton__inner {
    outline: none;
    border: none;
    background-color: #e24141;
    border-radius: 6px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    padding: 8px;
  }
}
@import '../../../variables';
@import '../../../mixins';

.NewListItemPrompt {
  grid-column: 1 / 5;
  padding: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #afafaf;
  display: flex;
  flex-direction: column;
  text-align: center;

  &:hover {
    border-color: #7f7f7f;
  }

  .NewListItemPrompt__input, .NewListItemPrompt__submit {
    font-size: 16px;
    outline: none;
    border: none;
  }

  .NewListItemPrompt__input {
    background-color: transparent;
    flex: 1;
    padding: 6px 12px;
    text-align: center;
  }

  .NewListItemPrompt__submit {
    font-size: 16px;
    margin: 0 12px;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    background-color: #09cb9f;
    color: #fff;
    transition: opacity 200ms ease;

    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    &:hover:not(.disabled) {
      opacity: 0.9;
    }
  }

  @include minWidth($size-mobile) {
    flex-direction: row;
    text-align: start;

    .NewListItemPrompt__input {
      text-align: initial;
    }
  }
}
$header-height: 88px;
$footer-height: 60px;

// colors
$dark-gray: #3a3a3a;
$app-white: #fff;
$app-black: #000;
$app-yellow: #ffdd57;

$app-background: $app-white;

$willAppear: 'willAppear';
$appearing: 'appearing';
$appeared: 'appeared';
$willEnter: 'willEnter';
$entering: 'entering';
$entered: 'entered';
$willExit: 'willExit';
$exiting: 'exiting';
$exited: 'exited';

$transitionStates: (
  $willAppear: 'will-appear',
  $appearing: 'appearing',
  $appeared: 'appeared',
  $willEnter: 'will-enter',
  $entering: 'entering',
  $entered: 'entered',
  $willExit: 'will-exit',
  $exiting: 'exiting',
  $exited: 'exited'
);

$size-mobile: 640px;

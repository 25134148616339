@import 'variables';

@mixin transitionState($states...) {
  @each $state in $states {
    &--#{map_get($transitionStates, $state)} {
      @content;
    }
  }
}

@mixin minWidth($width) {
  @media(min-width: $width) {
    @content;
  }
}

@mixin gridLayout() {
  display: grid;
  grid-template-columns: 55px 72px 1fr 76px;
}
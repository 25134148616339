.DeleteItemsContainer {
  display: grid;
  grid-template-columns: 55px 72px 1fr 76px;
  grid-column: 1/5;
  align-items: center;
  margin: 24px 0;
}

.DeleteItemsContainer__all, .DeleteItemsContainer__checked {
  font-style: italic;
  align-self: center;
  font-size: 14px;
  cursor: pointer;
}

.DeleteItemsContainer__all {
  grid-column: 1/3;
  justify-self: center;
}

.DeleteItemsContainer__checked {
 grid-column: 3/4;
}
@import '../../../variables';
@import '../../../mixins';

.ListItem {
  @include gridLayout();
  border-bottom: 1px solid #afafaf;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
  background-color: $app-white;

  @include minWidth($size-mobile) {
    .DeleteButton {
      transition: opacity 200ms ease;
      opacity: 0;
    }

    &:hover {
      border-color: #7f7f7f;

      .DeleteButton {
        opacity: 1;
      }
    }
  }

  .Checkbox__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ListItem__checkbox {
    cursor: pointer;
  }

  .ListItem__text {
    flex: 1;
    margin: 12px 0;
    line-height: 26px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ListItem__text--checked {
    text-decoration: line-through;
    opacity: 0.75;
  }
}